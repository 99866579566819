import React from "react"

import Header from "./header"

class Layout extends React.Component {
  render() {
    const { children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div className="container">
        <Header />
        <main>{children}</main>
        <footer>© {new Date().getFullYear()}, stevanpopovic.com</footer>
      </div>
    )
  }
}

export default Layout
