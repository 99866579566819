/**
 * Social component for links to social media
 */

import React from "react"
import { FaGithub, FaTwitter, FaLinkedin, FaEnvelope } from "react-icons/fa"
import { OutboundLink } from "gatsby-plugin-google-analytics"

function Social() {
  return (
    <div className="social">
      <OutboundLink className="twitter" href="https://twitter.com/StevanPopo"><FaTwitter title="Link to Stevan's Twitter profile" alt="See Stevan's Twitter Profile" /></OutboundLink>
      <OutboundLink className="linkedin" href="https://www.linkedin.com/in/stevanpopovic/"><FaLinkedin title="Link to Stevan's LinkedIn profile" alt="See Stevan's LinkedIn Profile" /></OutboundLink>
      <OutboundLink className="github" href="https://github.com/stevanpopo"><FaGithub title="Link to Stevan's GitHub profile" alt="See Stevan's GitHub Profile" /></OutboundLink>
      <OutboundLink className="email" href="mailto:hello@stevanpopovic.com"><FaEnvelope title="Link to email Stevan" alt="Send Stevan an email" /></OutboundLink>
    </div>
  )
}

export default Social
