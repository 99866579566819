/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import favicon16 from "../../content/assets/favicon-16x16.png"
import favicon32 from "../../content/assets/favicon-32x32.png"

function SEO({ description, lang, meta, title, thumbnail, thumbnailAlt, cardType }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            social {
              twitter
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const siteUrl = site.siteMetadata.siteUrl
  const authorTwitter = site.siteMetadata.social.twitter
  const thumbnailSrc = thumbnail && thumbnail.childImageSharp.sizes.src
  const card = cardType? cardType: `summary`

  const metaInformation = [
    {
      // need this to verify site ownership
      name: `google-site-verification`,
      content: `qQ87zklw_PVOB4009byUzeeQkjL9Xr14fvIdxYzP210`
    },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:site`,
      content: authorTwitter,
    },
    {
      name: `twitter:card`,
      content: card,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  if (thumbnailAlt) {
    metaInformation.push({
      name: `twitter:image:alt`,
      content: thumbnailAlt,
    })
  }

  if (thumbnailSrc) {
    metaInformation.push({
      name: `twitter:image`,
      content: `${siteUrl}${thumbnailSrc}`,
    },{
      name: `og:image`,
      content: `${siteUrl}${thumbnailSrc}`,
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      link={[
        { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` },
        { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon16}` },
      ]}
      meta={metaInformation.concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
