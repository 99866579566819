/**
 * Header component
 */

import React from "react"
import Social from "./social"
import { Link } from "gatsby"

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    };

    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle(e) {
    e.preventDefault();
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  render(){

    return(
      <header>
        <button className="mobile-nav" onClick={this.handleToggle}>Menu</button>

        <h2 className="logo">
          <Link to="/">Stevan Popovic'</Link>
        </h2>
  
        <nav role="navigation" className={this.state.isExpanded? "expanded" : ""}>
          <div aria-label="primary">
            <Link to="/">Home</Link>
            <Link to="/writing/">Writing</Link>
            <Link to="/projects/">Projects</Link>
          </div>
          <div aria-label="secondary">
            <Link to="/about/">About</Link>
            {/* <Link to="/newsletter/">Newsletter</Link> */}
            <Link to="/cv/">CV</Link>
            <Link to="/teaching/">Teaching</Link>
            <Link to="/notes/">Notes</Link>
            <Link to="/resources/">Resources</Link>
            <Link to="/tweets/">Tweets</Link>
          </div>
        </nav>
  
        <Social />
      </header>
    )
  }
}

export default Header